import Layout from "../../Layout";
import { Helmet } from "react-helmet";
import { useFormik } from "formik";
import * as Yup from "yup";
import "./style.css";
import useDesktopCheck from "../../hooks/useDesktopCheck";
import { useSubmitPartnerData } from "../../mutations/promotion-form";
import { Link, useNavigate } from "react-router-dom";
import { useEffect } from "react";

const validationSchema = Yup.object({
  code: Yup.string().required("Codul este obligatoriu."),
  firstName: Yup.string().required("Prenumele este obligatoriu."),
  lastName: Yup.string().required("Numele este obligatoriu."),
  email: Yup.string()
    .email("Email invalid.")
    .required("Emailul este obligatoriu."),
  phone: Yup.string()
    .matches(/^(\+40|0)[0-9]{9}$/, "Numarul de telefon nu este valid.")
    .required("Telefonul este obligatoriu."),
  county: Yup.string().required("Judetul este obligatoriu."),
  terms: Yup.boolean()
    .required("Regulamentul este obligatoriu.")
    .oneOf([true], "Consimțământul pentru Regulament este obligatoriu."),
  dataProcessing: Yup.boolean()
    .required("Consimțământul pentru prelucrarea datelor este obligatoriu.")
    .oneOf(
      [true],
      "Consimțământul pentru prelucrarea datelor este obligatoriu."
    ),
});

const title = "Caraiman – gust echilibrat, numai bun să-ți tragi sufletul ";
const description =
  "Descoperă rețeta naturală a berii Caraiman cu gust echilibrat, inspirat de liniștea muntelui, ca să-ți tragi sufletul oricând simți nevoia de o pauză.";

const Promotion = () => {
  const { mutate, data: response } = useSubmitPartnerData();
  const isOnDesktop = useDesktopCheck();
  const navigate = useNavigate();
  const counties = [
    "Alba",
    "Arad",
    "Argeș",
    "Bacău",
    "Bihor",
    "Bistrița-Năsăud",
    "Botoșani",
    "Brașov",
    "Brăila",
    "Buzău",
    "București",
    "Caraș-Severin",
    "Călărași",
    "Cluj",
    "Constanța",
    "Covasna",
    "Dâmbovița",
    "Dolj",
    "Galați",
    "Giurgiu",
    "Gorj",
    "Harghita",
    "Hunedoara",
    "Ialomița",
    "Iași",
    "Ilfov",
    "Maramureș",
    "Mehedinți",
    "Mureș",
    "Neamț",
    "Olt",
    "Prahova",
    "Satu Mare",
    "Sălaj",
    "Sibiu",
    "Suceava",
    "Teleorman",
    "Timiș",
    "Tulcea",
    "Vaslui",
    "Vâlcea",
    "Vrancea",
  ];

  const formik = useFormik({
    initialValues: {
      code: "",
      lastName: "",
      firstName: "",
      email: "",
      phone: "",
      county: "",
      terms: false,
      dataProcessing: false,
    },

    validationSchema: validationSchema,

    onSubmit: async (values) => {
      const data = {
        cod: values.code,
        nume: values.lastName,
        prenume: values.firstName,
        email: values.email,
        telefon: values.phone,
        judet: values.county,
      };

      mutate(data);
      if (formik.isValid) {
        formik.resetForm();
        formik.setFieldValue("terms", false);
        formik.setFieldValue("dataProcessing", false);
      }
    },
  });

  useEffect(() => {
    if (response && response?.status === 1) {
      //@ts-ignore
      gtag("event", "success_code");
      navigate("/succes");
    } else if (response && response?.status !== 1) {
      //@ts-ignore
      gtag("event", "failed_code");
    }
  }, [response]);

  return (
    <Layout>
      <Helmet>
        <title>Promotia | {title}</title>
        <meta name="title" content={title} />
        <meta name="description" content={description} />
      </Helmet>
      <div className="flex flex-col items-center bg-secondary-grey">
        <div
          className="flex items-center justify-center relative w-full bg-cover bg-bottom lg:bg-center bg-no-repeat z-1 py-20"
          style={{
            backgroundImage: isOnDesktop
              ? "url('/images/promotia/promotia_bg.png')"
              : "url('/images/promotia/promotia_bg_mobile.png')",
            height: 800,
          }}
        />
        <form
          onSubmit={formik.handleSubmit}
          className="flex flex-col justify-center items-start lg:items-center p-10 max-w-7xl"
        >
          <div className="flex flex-col lg:flex-row items-center w-full space-y-2 lg:space-y-0 lg:space-x-12">
            <div className="lg:w-1/2 text-black lg:mt-5">
              <h2 className="font-bold text-primary-green text-3xl lg:text-5xl uppercase">
                AI GĂSIT UN COD?
              </h2>
              <p className="font-sans font-normal text-primary-green text-lg lg:text-xl mt-2 lg:leading-10">
                Înscrie-l între <strong>1.08 și 31.10.2024</strong> și intri în
                tragerea la sorți pentru <strong>100.000 de euro,</strong> ca
                să-ți faci cabana ta.
              </p>
              <div className="flex flex-row items-start font-sans space-x-2 mt-4">
                <input
                  id="code"
                  name="code"
                  type="text"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.code}
                  placeholder="Cod"
                  style={{ fontSize: "28px" }}
                  className={`font-sans placeholder-secondary-grey text-secondary-grey block w-full sm:text-sm bg-primary-green rounded-md px-4 py-4 ${
                    formik.touched.code && formik.errors.code
                      ? "border-red-500"
                      : ""
                  } font-semibold uppercase`}
                />
                <p>*</p>
              </div>
              {formik.touched.code && formik.errors.code && (
                <span className="font-sans text-red-500 text-xs -mt-2">
                  {formik.errors.code}
                </span>
              )}
            </div>
            <div className="flex flex-col lg:w-1/2 w-full space-y-2 rounded font-sans">
              {/* Name Field */}
              <div className="space-y-1 lg:mt-10">
                <div className="flex flex-row space-x-2">
                  <input
                    id="lastName"
                    name="lastName"
                    type="text"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.lastName}
                    placeholder="Nume"
                    className={`placeholder-secondary-grey text-secondary-grey block w-full sm:text-sm bg-primary-green rounded-md px-4 py-3 ${
                      formik.touched.lastName && formik.errors.lastName
                        ? "border-red-500"
                        : ""
                    }`}
                  />
                  <p>*</p>
                </div>
                {formik.touched.lastName && formik.errors.lastName && (
                  <span className="text-red-500 text-xs -mt-2">
                    {formik.errors.lastName}
                  </span>
                )}
                {/* First Name Field */}
                <div className="flex flex-row space-x-2">
                  <input
                    id="firstName"
                    name="firstName"
                    type="text"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.firstName}
                    placeholder="Prenume"
                    className={`placeholder-secondary-grey text-secondary-grey block w-full sm:text-sm bg-primary-green rounded-md px-4 py-3 ${
                      formik.touched.firstName && formik.errors.firstName
                        ? "border-red-500"
                        : ""
                    }`}
                  />
                  <p>*</p>
                </div>
                {formik.touched.firstName && formik.errors.firstName && (
                  <span className="text-red-500 text-xs -mt-2">
                    {formik.errors.firstName}
                  </span>
                )}
                {/* Email Field */}
                <div className="flex flex-row space-x-2">
                  <input
                    id="email"
                    name="email"
                    type="email"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.email}
                    placeholder="Email"
                    className={`placeholder-secondary-grey text-secondary-grey block w-full sm:text-sm bg-primary-green rounded-md px-4 py-3 ${
                      formik.touched.email && formik.errors.email
                        ? "border-red-500"
                        : ""
                    }`}
                  />
                  <p>*</p>
                </div>
                {formik.touched.email && formik.errors.email && (
                  <span className="text-red-500 text-xs -mt-2">
                    {formik.errors.email}
                  </span>
                )}
                {/* Phone Field */}
                <div className="flex flex-row space-x-2">
                  <input
                    id="phone"
                    name="phone"
                    type="tel"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.phone}
                    placeholder="Telefon"
                    className={`placeholder-secondary-grey text-secondary-grey block w-full sm:text-sm bg-primary-green rounded-md px-4 py-3 ${
                      formik.touched.phone && formik.errors.phone
                        ? "border-red-500"
                        : ""
                    }`}
                  />
                  <p>*</p>
                </div>
                {formik.touched.phone && formik.errors.phone && (
                  <span className="text-red-500 text-xs -mt-2">
                    {formik.errors.phone}
                  </span>
                )}
                {/* county Field */}
                <div className="flex flex-row space-x-2 relative">
                  <select
                    id="county"
                    name="county"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.county}
                    className={`placeholder-secondary-grey text-secondary-grey block w-full sm:text-sm bg-primary-green rounded-md px-4 py-3 appearance-none`}
                  >
                    <option value="">Selectează un județ</option>
                    {counties.map((county) => (
                      <option key={county} value={county}>
                        {county}
                      </option>
                    ))}
                  </select>
                  <div className="pointer-events-none absolute inset-y-0 right-4 flex items-center px-2 text-white">
                    <svg
                      className="fill-current h-4 w-4"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                    >
                      <path d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" />
                    </svg>
                  </div>
                  <p>*</p>
                </div>
                {formik.touched.county && formik.errors.county && (
                  <span className="text-red-500 text-xs -mt-2">
                    {formik.errors.county}
                  </span>
                )}
              </div>

              <span className="md:ml-4 font-sans font-normal text-primary-green text-sm md:text-base">
                Câmpurile marcate cu * sunt obligatorii.
              </span>
            </div>
          </div>
          <div className="flex flex-col space-y-1 mt-4">
            <div className="form-check flex items-center space-x-2">
              <input
                id="terms"
                type="checkbox"
                className="form-checkbox text-primary-green h-4 w-4 rounded"
                checked={formik.values.terms}
                onChange={formik.handleChange}
              />
              <span className="text-primary-green font-sans text-sm">
                Sunt de acord cu{" "}
                <Link to="/regulament-promotie">
                  <strong>regulamentul campaniei.</strong>
                </Link>
              </span>
            </div>
            {formik.touched.terms && formik.errors.terms && (
              <span className="font-sans text-red-500 text-xs -mt-2">
                {formik.errors.terms}
              </span>
            )}
            <div className="form-check flex items-center space-x-2">
              <input
                id="dataProcessing"
                type="checkbox"
                className="form-checkbox text-primary-green h-4 w-4 rounded"
                checked={formik.values.dataProcessing}
                onChange={formik.handleChange}
              />
              <label
                htmlFor="dataProcessing"
                className="text-primary-green font-sans text-sm"
              >
                Sunt de acord cu prelucrarea datelor cu caracter personal.
              </label>
            </div>
            {formik.touched.dataProcessing && formik.errors.dataProcessing && (
              <span className="font-sans text-red-500 text-xs -mt-2">
                {formik.errors.dataProcessing}
              </span>
            )}
          </div>
          {response && response.message && (
            <p className="text-red-500 font-sans text-xl mt-4 lg:mt-8 max-w-[600px]">
              {response.message}
            </p>
          )}
          <button
            type="submit"
            className="cursor-pointer w-52 lg:w-96 mt-8 lg:mt-16 relative"
          >
            <img
              src="/images/promotia/buton_inscrie.png"
              alt="buton inscriere"
            />
            <div className="absolute w-40 lg:w-80 custom-lg:w-96 -right-28 lg:-right-64 custom-lg:-right-80 -top-[65%] custom-md:-top-[70%] custom-lg:-top-[77%]">
              <img src="/images/promotia/valtoare.png" alt="valtoare" />
            </div>
          </button>
        </form>
        {/* Additional promotional content */}
        <div className="flex flex-col max-w-7xl w-full lg:mt-16">
          <div className="flex flex-col items-start md:w-1/2 p-10">
            <h2 className="font-bold text-primary-green text-3xl lg:text-5xl uppercase">
              {isOnDesktop ? (
                "AI GĂSIT O BERE?"
              ) : (
                <>
                  AI GĂSIT
                  <br />O BERE?
                </>
              )}{" "}
            </h2>
            <p className="font-sans text-left text-primary-green">
              Ia capacul sau cheița câștigătoare și fă un popas la cel mai
              apropiat centru de premiere, în perioada{" "}
              <strong>1.08.2024 - 31.01.2025.</strong>
            </p>
            <div className="flex flex-col lg:flex-row text-center mt-4 lg:space-x-2 space-y-2 lg:space-y-0">
              <button
                onClick={() => navigate("/promotia/centre")}
                className="bg-primary-green text-white font-sans font-semibold text-xl px-5 py-2"
              >
                VEZI LISTA CENTRE
              </button>
              <button
                onClick={() => navigate("/promotia/centre-de-premiere")}
                className="bg-primary-green text-white font-sans font-semibold text-xl px-5 py-2"
              >
                VEZI HARTA CENTRE
              </button>
            </div>
          </div>
        </div>

        {/* Product display */}
        <div className="flex flex-col max-w-7xl w-full -mt-10 pb-10 relative">
          <div className="lg:absolute flex flex-col top-48 custom-lg:top-56 px-10 mt-6">
            <h2 className="font-bold text-primary-green text-3xl lg:text-5xl uppercase">
              Produse participante
            </h2>
            <p className="font-sans text-left text-primary-green">
              Trage-ți sufletul cu orice Caraiman
              <br /> la sticlă, doză sau PET.
            </p>
          </div>
          {isOnDesktop ? (
            <div className="w-full max-w-[1800px] custom-md:-right-64 custom-lg:-right-80 custom-md:-top-[70%] custom-lg:-top-[77%]">
              <img src="/images/promotia/packshot.png" alt="packshot" />
            </div>
          ) : (
            <div className="w-full px-4">
              <img src="/images/promotia/packshot_mobile.png" alt="packshot" />
            </div>
          )}
        </div>
        <div className="absolute bottom-0 left-0 right-0 flex flex-row items-center justify-center p-2 md:justify-start z-20">
          <p className="text-primary-green text-xs md:text-xl font-sans font-semibold z-20">
            Consumă Caraiman în mod responsabil.
          </p>
          <img
            className="ml-2 w-16 lg:w-24 z-20"
            src="/images/icons-green.png"
            alt="icons"
          />
        </div>
      </div>
    </Layout>
  );
};

export default Promotion;
